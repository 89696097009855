<template lang="pug">
Loading(:time="60" ref="loading" :autoClose="false")
.maincontain#fvl
  img.bg.bg--left(
    src="@/assets/images/fvlbg-topleft.png"
    :class="{'bg--hideleft': isShowExhibition || isShowIntro}"
  )
  img.bg.bg--right(
    src="@/assets/images/bg-rightbottom.png"
    :class="{'bg--hideright': isShowExhibition || isShowIntro}"
  )
  transition(name="content-fade")
    Works(
      v-if="dataIsReady && isShowArtWork"
      :works="filteredWorks"
      ref="works"
    )
  .side(
    :class="{'side--down': !isSideBarOpen}"
  )
    div.d-flex.flex-column(
      :class="{'sidebar--fix': isFixdSidebar}"
    )
    .sidebar.order-2.order-lg-1
      .flex-center.sidebar__arrow(
        :class="{'sidebar__arrow--active': isSideBarOpen}"
        @click="isSideBarOpen = !isSideBarOpen"
      )
        img(:src="require(`@/assets/images/sidebar-arrow.svg`)")
      SidebarCollapse(
        :children="false"
        :isClose="collapseCloseArray[0]"
        @click="renderCollapse(0, 0)"
      )
        template(v-slot:title) {{ $t('shared.all') }}
      SidebarCollapse(
        v-for="(location, index) in locations"
        :key="index"
        :children="true"
        :isClose="collapseCloseArray[index+1]"
        @click="renderCollapse(index+1, location.work_location_id); isFixdSidebar = false;"
      )
        template(v-slot:title) {{ location[`name_${localeLang}`] }}
        template(v-slot:content)
          .sidebar__item(
            v-for="(work, index) in location.works"
            :key="index"
            @click="moveToArtWork(work.slug)"
          )
            .sidebar__item_text {{ work[`work_${localeLang}`]['title'] }}

    .d-flex.flex-row.flex-lg-column.order-1.order-lg-2
      .siderbar__date.siderbar__date--left.flex-center(@click="renderCollapse('date'); isFixdSidebar = true;")
        .siderbar__date__text {{ $t('fvl.schedule') }}
      .siderbar__date.siderbar__date--right.flex-center(@click="renderCollapse('intro'); isFixdSidebar = true;")
        .siderbar__date__text {{ $t('fvl.about') }}

  //- .content(v-if="isShowArtWork")
    .row.mt-3.mb-5
      .col-lg-2
      .col-sm-12.col-lg-10.d-flex.flex-wrap
        .artwork.d-flex.flex-column.align-items-start.justify-content-end.p-3(
          v-for="(work,index) in filteredWorks" :key="index"
          :style="{backgroundImage:`url(${$getPhotoUrl(work.photo_1)})`}"
          @click="toArtWork(work.slug)"
        )
          .artwork__title(v-if="work.work_zh.title !== work.work_en.title") {{ work.work_zh.title }}
          .artwork__title {{ work.work_en.title }}
  transition(name="content-fade")
    .content.content--exhibition(v-if="isShowExhibition")
      .row
        .col-12.col-lg-6(v-for="(worksTime, key) in fvlWorks" :key="key")
          .exhibition
            .exhibition__title {{ formatFvlWorkDate(key.split('_')[0]) }}. - {{ formatFvlWorkDate(key.split('_')[1]) }}.
            .exhibition__content(v-for="(contents, timeIndex) in worksTime" :key="timeIndex")
              .exhibition__content__time {{ timeIndex }}
              .exhibition__content__line
              .d-flex.flex-column
                .exhibition__content__item(v-for="(content, index) in contents" :key="index" @click="handleRedirectToWork(content.workEnName)")
                  .exhibition__content__itemblock
                    .exhibition__content__text {{ content.groupZhName }}｜{{ content.workZhName }}｜{{ content.time }} mins
                    .exhibition__content__text {{ content.groupEnName }}｜{{ content.workEnName }}
                  div
                    .exhibition__content__next.flex-center
                      img(:src="require(`@/assets/images/banner-arrow.svg`)")
  transition(name="content-fade")
    .content.content--intro(v-if="isShowIntro")
      .row
        .col-12
          img(:src="require(`@/assets/images/fvl-intro.png`)")
        .col-12
          .intro
            .intro__title FUTURE VISION LAB
            .intro__content {{ $t('fvl.about.paragrapph1') }}
              br
              br
              | {{ $t('fvl.about.paragrapph2') }}
              br
              br
              | {{ $t('fvl.about.paragrapph3') }}
        .col-12
          .intro
            .intro__title.intro__title--ch {{ $t('fvl.principle.title') }}
            .intro__content {{ $t('fvl.priciple.content') }}
</template>

<script>
import ArtistModal from "@/component/ArtistModal.vue";
import Loading from "@/component/Loading";
import SidebarCollapse from "@/component/SidebarCollapse.vue";
import Works from "@/component/Works/";
import { mapState } from "vuex";
import { ref } from "vue";
import dayjs from "dayjs";
import fvlData from "./temp/fvl-data";
import { getWorkSlug } from "@/utils/utils";
import { useRouter } from "vue-router";

export default {
  name: "fvl",
  components: {
    ArtistModal,
    SidebarCollapse,
    Works,
    Loading
  },
  data() {
    return {
      collapseCloseArray: [false, true], // 初始化sidebar選單要不要打開
      isShowExhibition: false,
      isShowArtWork: true,
      isShowIntro: false,
      isSideBarOpen: false,
      selectedLocationId: 0,
      isFixdSidebar: false,
    };
  },
  methods: {
    renderCollapse: function (collapseIndex, locationId) {
      this.selectedLocationId = locationId;
      if (collapseIndex === "date") {
        this.isShowExhibition = true;
        this.isShowArtWork = false;
        this.isShowIntro = false;
      } else if (collapseIndex === "intro") {
        this.isShowExhibition = false;
        this.isShowArtWork = false;
        this.isShowIntro = true;
      } else {
        this.isShowExhibition = false;
        this.isShowArtWork = true;
        this.isShowIntro = false;
      }
      if (this.collapseCloseArray[collapseIndex] === false) {
        this.collapseCloseArray[collapseIndex] = true;
      } else {
        this.collapseCloseArray = this.collapseCloseArray.map(function (
          val,
          index
        ) {
          return collapseIndex !== index;
        });
      }
    },
    toArtWork(slug) {
      this.$store.dispatch("updateCurrentWorkType", "fvl");
      this.$router.push({ path: `/artworks/${slug}` });
    },
    moveToArtWork(slug){
      this.$refs.works.moveToArtWork(slug)
    }
  },
  computed: {
    ...mapState(["workLocations", "worksFmaf", "dataIsReady"]),
    filteredWorks() {
      if (this.worksFmaf)
        return this.worksFmaf.filter((w) =>
          w.location.name_en.includes("FUTURE VISION LAB")
        );
      return [];
        
      // if (this.selectedLocationId === 0) {
      //   if (this.worksFmaf)
      //     return this.worksFmaf.filter((w) =>
      //       w.location.name_en.includes("FUTURE VISION LAB")
      //     );
      //   return [];
      // }
      // return this.worksFmaf.filter(
      //   (w) =>
      //     w.location.work_location_id === this.selectedLocationId &&
      //     w.location.name_en.includes("FUTURE VISION LAB")
      // );
    },
    locations() {
      if (this.workLocations)
        return this.workLocations.filter((l) =>
          l.name_en.includes("FUTURE VISION LAB")
        );
      return [];
    },
    localeLang() {
      return this.$i18n.locale;
    },
  },
  watch: {
    filteredWorks(newValue, oldValue) {
      if (oldValue.length !== 0) {
        setTimeout(() => {
          if (this.dataIsReady) this.$refs.works.resetWorks();
        }, 800);
      }
    },
  },
  setup() {
    const router = useRouter();
    const fvlWorks = ref(fvlData);

    const checkDateValid = (date) => {
      return !dayjs(date).isValid();
    };

    const formatFvlWorkDate = (date) => {
      if (checkDateValid(date)) return "N/A";

      return dayjs(date).format("MM/DD ddd");
    };

    const handleRedirectToWork = async (workEnName) => {
      if (!workEnName) return;

      const slug = getWorkSlug(workEnName);

      if (slug) {
        await router.push(`/artWorks/${slug}`);
      }
    };

    return {
      fvlWorks,
      formatFvlWorkDate,
      handleRedirectToWork,
    };
  },
};
</script>

<style lang="sass" scoped>
*
  transition: 0.3s ease-out

.row
  margin: 0

#fvl
  padding: 110px
  width: 100%
  min-height: 100vh
  position: relative
  &::after
    background-color: #000
    content: ' '
    position: fixed
    left: 0
    top: 0
    width: 100vw
    height: 100vh
    z-index: -2


  +rwd-md
    padding: 64px 9px 0 9px

.bg
  position: fixed
  transition: 1s ease-in
  &--left
    left: 0
    top: 0
    +rwd-md
      display: none

  &--right
    bottom: -10%
    right: 0
    width: 57%
    +rwd-md
      display: none

  &--hideleft
    transform: translate(-100%, -100%)
    left: 0

  &--hideright
    transform: translate(100%, 100%)
    right: 0

.side
  z-index: 999
  width: 22%
  display: inline-block
  position: relative
  max-width: 258px
  +rwd-md
    position: fixed
    right: 0
    top: auto
    bottom: -1px
    width: 100%
    padding: 0 9px
    display: flex
    flex-direction: column-reverse
    max-width: none
    border: none

    &--down
      top: calc(100% - 161px)
      bottom: auto
      transition: 1s

.content
  position: relative
  display: inline-block
  vertical-align: top
  width: 74%
  padding-bottom: 0
  +rwd-md
    margin-left: 0
    width: 100%
    padding-bottom: 100px

  &--exhibition
    max-width: 1150px
    padding-left: 36px

  +rwd-md
    padding-left: 0

  &--intro
    margin-top: -27px
    padding-left: 36px
    max-width: 720px
    +rwd-md
      margin-top: 0
      padding: 24px 24px 150px 24px

    img
      max-width: 100%

.sidebar
  padding: 17px 0
  width: 100%
  background-color: #fff
  border: solid 1px #000
  +rwd-md
    max-width: none
    width: 100%
    bottom: 0

  &--fix
    position: fixed
    width: 100%
    max-width: 258px
    +rwd-md
      width: 100%
      position: relative
      max-width: none

  &__title
    padding: 18px 27px
    font-size: 14px
    font-weight: 700

    &--active
      background: linear-gradient(91.9deg, #BDBDBD 2.61%, #A2A2A2 49.06%, #E7E7E7 73.1%, #8B8B8B 97.74%)
      margin-left: -8px

  &__item
    padding: 5px 8px 5px 60px
    cursor: pointer
    display: flex
    align-items: center

    &:hover > .sidebar__item_text
      background-color: #000
      color: #fff

    &__text
      display: inline

.siderbar__date
  margin-top: 15px
  margin-bottom: 0
  width: 100%
  max-width: 258px
  padding: 18px 0
  cursor: pointer
  flex-direction: row
  background: linear-gradient(91.9deg, #BDBDBD 2.61%, #A2A2A2 49.06%, #E7E7E7 73.1%, #8B8B8B 97.74%)
  +rwd-md
    max-width: none
    margin-bottom: 7px

  &__text
    font-weight: 700

  &--left
    margin-right: 0
    +rwd-md
      margin-right: 2px

  &--right
    margin-left: 0
    +rwd-md
      margin-left: 2px

.exhibition
  padding: 0 10px
  margin-top: 36px
  +rwd-md
    margin-top: 24px

  &__title
    color: #fff
    font-size: 36px
    margin-bottom: 8px
    +rwd-md
      font-size: 24px

.artwork
  z-index: 1
  width: 48%
  min-height: 200px
  background-repeat: no-repeat
  background-size: auto 180%
  color: white
  margin-right: 2%
  margin-top: 2%
  position: relative
  +rwd-sm
    background-size: 200% auto

  &:hover
    cursor: pointer

  &:before
    content: ' '
    width: 100%
    height: 100%
    position: absolute
    left: 0
    top: 0
    z-index: -1
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.16) 38.65%, rgba(0, 0, 0, 0) 86.29%)

  & > div
    z-index: 5
    width: 100%
    text-align: left

.exhibition__content
  margin-bottom: 8px
  display: flex
  flex-direction: row
  +rwd-md
    flex-direction: column

  &__time
    color: #fff
    min-width: 36px
    +rwd-md
      margin-bottom: 12px

  &__line
    border-left: solid 1px #fff
    width: 1px
    height: auto
    margin: 0 16px

  &__item
    display: inline-flex
    align-items: center
    align-self: start
    cursor: pointer
    border-radius: 4px
    background-color: #414141
    padding: 8px 8px 8px 8px
    margin-bottom: 8px
    width: auto
    +rwd-sm
      max-width: 100%

    &:hover
      background-color: #0021F4

    &:hover .exhibition__content__next
      opacity: 1
      transform: scaleY(1)
      width: 32px
      height: 32px

  &__itemblock
    width: auto
    +rwd-sm
      max-width: 100%

  &__text
    color: #fff
    +rwd-md
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden

  &__next
    opacity: 0
    transform: scale(0)
    transform-posotion: center left
    margin-left: 8px
    transition: 1s
    width: 0
    height: 0
    border-radius: 50%
    padding: 0 6px
    cursor: pointer
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.51) 0%, #000000 100%)

    img
      width: 100%

.content-fade-enter-active
  transition: all 1s ease-in

.content-fade-leave-active
  transition: all 1s ease-in

.content-fade-enter-from,
.content-fade-leave-to
  opacity: 0

.sidebar__arrow
  display: none
  padding: 0 0 12px 0
  cursor: pointer
  +rwd-md
    display: flex

  img
    width: 19px
    transform: rotate(180deg)

  &--active
    img
      transform: rotate(0deg)

.intro
  padding: 13px 0

  &__title
    color: #fff
    font-size: 36px
    margin-bottom: 24px

    &--ch
      font-weight: 900

  &__content
    color: #fff
    line-height: 23px
</style>
